// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
// import Popper from "popper.js";

// to install plugin to webpack:
// - npm install moment
// - add plugin in webpack.config.js
// - add here window.moment = require('moment');

window.AOS = require("aos");
window.jQuery = $;
window.$ = $;
window.slick = require("slick-slider");
window.moment = require("moment");
window.Chart = require("chart.js");

require("bootstrap");

// Smooth scrolling using jQuery easing
$('a.js-scroll-trigger[href*="#"]:not([href="#"])').on("click", function () {
  if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
    if (target.length) {
      $("html, body").animate(
        {
          scrollTop: target.offset().top - 120
        },
        1000,
        "easeInOutExpo"
      );
      return false;
    }
  }
});

$(".js-scroll-trigger").on("click", function () {
  $(".navbar-collapse").removeClass("show");
});

// Add white background if menu clicked from top
$(".navbar-toggler").on("click", function () {
  $("#mainNav").addClass("navbar-clicked");
});

// Add class to Nav if scrolled
$(window).on("scroll", function () {
  if ($("#mainNav").offset().top > 100) {
    $("#mainNav").addClass("navbar-scrolled");
  } else {
    $("#mainNav").removeClass("navbar-scrolled");
  }
});

// Scroll spy - Add active class to scrolled menu section
$("body").scrollspy({
  target: "#mainNav",
  offset: 200
});

// Funkcia nastavi cookie
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + 3 * 30 * 24 * 3600000); // cookie expiration (pocet milisekund) 3 months
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// Funkcia na zistenie cookie
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// product box slider
$(function () {
  let pocetRokov = $(".pocet-rokov");
  let vstupnaPlatba1 = $(".vstupna-platba1");
  let vstupnaPlatba2 = $(".vstupna-platba2");
  let vstupnaPlatba3 = $(".vstupna-platba3");

  $("#slider0").slider({
    range: "min",
    value: 15,
    min: 10,
    max: 20,
    step: 1,

    create: function (event, ui2) {
      pocetRokov.text("15");
      vstupnaPlatba1.text(50 * 15 * 12 * 0.05);
      vstupnaPlatba2.text(70 * 15 * 12 * 0.05);
      vstupnaPlatba3.text(100 * 15 * 12 * 0.05);
    },

    slide: function (event, ui2) {
      pocetRokov.text(ui2.value);
      vstupnaPlatba1.text(50 * ui2.value * 12 * 0.05);
      vstupnaPlatba2.text(70 * ui2.value * 12 * 0.05);
      vstupnaPlatba3.text(100 * ui2.value * 12 * 0.05);
    }
  });
});

// Cookies bar
$(document).ready(function () {
  if (getCookie("cookies") == "clicked") {
    $("#cookie-dialog").addClass("d-none");
  } else {
    $("#cookie-dialog").removeClass("d-none");
  }

  // hide cookie listu on click
  $("#cookie-button").on("click", function () {
    setCookie("cookies", "clicked", 1);
    $("#cookie-dialog").addClass("d-none");
  });
});

// Zobrazenie mince
var element = document.getElementById("coins");

function showCoin(years, eur) {
  let maxHodnota = 150 * 9;
  let aktualnaHodnota = years * eur;
  let vypocetHodnota = (aktualnaHodnota / maxHodnota) * 30;

  element.innerHTML = "";

  for (let i = 0; i < vypocetHodnota; i++) {
    let minca = document.createElement("IMG");
    minca.setAttribute("src", "src/img/coin.webp");
    minca.classList.add("calculator__image");
    minca.setAttribute("data-aos", "fade-down");
    minca.setAttribute("data-aos-delay", i * 100);
    element.prepend(minca);
  }
}

// Kalkulačka slider (jQuery UI)
$(function () {
  let years = $("#sliderYears");
  let eur = $("#custom-slider");
  let final = $("#sliderEur");
  let bitcoin = $("#sliderBitcoin");
  let gold = $("#sliderGold");
  let spel = $("#sliderSp");

  var finalYears = 2020 - 3;
  var uiYears = 3;
  var finalEur = 70;

  $("#slider1").slider({
    range: "min",
    value: 3,
    min: 0,
    max: 6,

    create: function () {
      years.text(2020 - $(this).slider("value"));
      final.text("€3,570");
      bitcoin.text("€45,532");
      gold.text("€4,618");
      spel.text("€5,339");

      showCoin(uiYears, finalEur);
    },

    slide: function (event, ui) {
      years.text(2020 - ui.value);
      uiYears = ui.value;
      finalYears = 2020 - ui.value;

      fetch("src/json/data.json")
        .then(response => response.json())
        .then(json => {
          for (const val in json) {
            if (val == finalYears) {
              bitcoin.text("€" + json[finalYears][0].bitcoin[finalEur].toLocaleString());
              gold.text("€" + json[finalYears][1].gold[finalEur].toLocaleString());
              spel.text("€" + json[finalYears][2].sp[finalEur].toLocaleString());
              final.text("€" + json[finalYears][3].cash[finalEur].toLocaleString());
            }
          }
        });

      setTimeout(function () {
        showCoin(uiYears, finalEur);
      }, 200);
    }
  });

  $("#slider2").slider({
    range: "min",
    value: 70,
    min: 50,
    max: 100,
    step: 10,

    create: function () {
      eur.text("€" + finalEur);
    },

    slide: function (event, ui2) {
      eur.text("€" + ui2.value);
      finalEur = ui2.value;

      fetch("src/json/data.json")
        .then(response => response.json())
        .then(json => {
          for (const val in json) {
            if (val == finalYears) {
              bitcoin.text("€" + json[finalYears][0].bitcoin[finalEur].toLocaleString());
              gold.text("€" + json[finalYears][1].gold[finalEur].toLocaleString());
              spel.text("€" + json[finalYears][2].sp[finalEur].toLocaleString());
              final.text("€" + json[finalYears][3].cash[finalEur].toLocaleString());
            }
          }
        });

      setTimeout(function () {
        showCoin(uiYears, finalEur);
      }, 200);
    }
  });
});

// product-explainer
// $(".teal-wrap").hover(function () {
//   $(this).toggleClass("teal-expander");
//   $(this).children(".product-explainer").toggleClass("d-none");
// });

// Main initialization of AOS
$(window).on("load", function () {
  AOS.init({
    once: true
  });
});

// Set language to localStorage
$(document).ready(function () {
  // Set default lang
  var currLang = localStorage.getItem("lang");
  if (!currLang) {
    localStorage.setItem("lang", "sk");
  }

  // Language picker toggler
  $(".lang-picker").on("click", function () {
    let lang = $(this).attr("id");
    if (currLang != lang) {
      localStorage.setItem("lang", lang);
    }
  });
});

// Toggle active class on btn-graph
$(".btn-graph").on("click", function () {
  $(".btn-graph").removeClass("active");
  $(this).addClass("active");
});

//scroll save-button
scrollbtn = document.getElementById("scroll-savebutton");

window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    scrollbtn.style.display = "block";
  } else {
    scrollbtn.style.display = "none";
  }
}

//disclaimer roll

$("#calculatorbutton").click(function () {
  $("#calculator-disclaimer-long").toggleClass("d-none");
});

//night mode

$("#night-button").click(function () {
  $("#graph-section").toggleClass("bg-black");
  $("#night-button").toggleClass("night-button-white");
  $("#oneUSDtitle1").toggleClass("graph-text-white");
  $("#oneUSDtitle2").toggleClass("graph-text-white");
  $("#graphundertitle").toggleClass("graph-text-white");
  $("#oneUSDundertitle1").toggleClass("graph-text-white");
  $("#oneUSDundertitle2").toggleClass("graph-text-white");
  $("#undertitle-legend").toggleClass("graph-text-white");
});

// Typewriter animation
function typeString(selector, value, seconds, delay) {
  let el = document.querySelector(selector);
  let word = "";
  let char = "";

  setTimeout(function () {
    for (let i = 0; i <= value.length; i++) {
      setTimeout(function timer() {
        char = word + value.charAt(i);
        el.innerText = char;
        word = char;
      }, i * seconds);
    }
  }, delay);
}

if (localStorage.getItem("lang") == "sk") {
  typeString("#h1", "Vaše", 100, 50);
  typeString("#h2", "bitcoinové", 80, 500);
  typeString("#h3", "sporenie", 80, 1300);
  typeString("#h4", ".", 5, 2000);
} else {
  typeString("#h1", "Your", 100, 50);
  typeString("#h2", "bitcoin", 80, 500);
  typeString("#h3", "savings plan", 80, 1300);
  typeString("#h4", ".", 5, 2400);
}

$(".reviews-slick").slick({
  infinite: true,
  autoplay: true,
  arrows: false,
  speed: 3000,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    }
  ]
});

// Get broker ID to localstorage
$(document).ready(function () {
  var uri = window.location.search.substring(1);
  var params = new URLSearchParams(uri);

  var brokerId = params.get("broker");
  console.log(brokerId);

  var referenceId = params.get("ref");
  console.log(referenceId);

  if (brokerId) {
    localStorage.setItem("broker", brokerId);
  }

  if (referenceId) {
    localStorage.setItem("referenceId", referenceId);
  }

  if (localStorage.getItem("broker") != null && localStorage.getItem("broker") != undefined) {
    var localBroker = localStorage.getItem("broker");

    var href = $(".app-button").attr("href");
    $(".app-button").attr("href", href + "?tab=register" + "&broker=" + localBroker);
  }

  if (localStorage.getItem("referenceId") != null && localStorage.getItem("referenceId") != undefined) {
    var localReferenceid = localStorage.getItem("referenceId");

    var href = $(".app-button").attr("href");
    $(".app-button").attr("href", href + "?tab=register" + "&ref=" + localReferenceid);
  }

  console.log($(".app-button").attr("href"));
});

// set year in footer
yearEl = document.getElementById("getyear");
const d = new Date();
let getyear = d.getFullYear();
yearEl.innerHTML = getyear;


$(".php__btn-send").on("click", function () {
  $(".contact__box--before").addClass("d-none");
  $(".contact__box--after").removeClass("d-none");
});